.fixed-bottom {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeec;
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
  padding: 10px 3rem;
  z-index: 1000;
}

.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Center text inside table cells */
table th,
table td {
  text-align: center;
  vertical-align: middle;
}

.cds--accordion--start .cds--accordion__heading {
  align-items: center !important;
}

.xpa-action-btns .cds--btn {
  padding: 10px !important;
}

.html-render-content li {
  margin-bottom: 0.75rem;
  /* Adds spacing after each li */
  line-height: 1.5;
  /* Optional: improve readability */
}

.html-render-content p {
  margin-bottom: 1rem;
}

.html-render-content ul {
  padding-left: 1.5rem;
  list-style-type: disc;
}