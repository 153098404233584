.cds--data-table-v2 thead th .text-center {
  display: block;
}

.cds--toolbar-search-container-active {
  width: 100% !important;
}

.cds--batch-actions--active {
  z-index: 1111;
}

.text-center .cds--table-header-label {
  text-align: center !important;
}


@media (min-width: 990px) and (max-width: 1180px) {
  .cds--toolbar-search-container-active {
    width: 35% !important;
  }
}

.cds--toolbar-search-container-active {
  width: 50% !important;
}

.xpa-actionlist-position .cds--batch-summary {
  top: 1rem;
}

.xpa-actionlist-position .cds--action-list {
  /* position: unset; */
  margin-left: 9rem;
  overflow-x: scroll;
}

.cds--batch-actions .cds--btn--primary {
  padding: calc(0.875rem - 3px) 15px calc(0.875rem - 3px) 15px !important;
}

.cds--batch-actions {
  background-color: var(--secondary-color) !important;
  padding-right: 0rem !important;
}

.xpa-actionlist-position .cds--action-list::-webkit-scrollbar {
  display: none;
}

/* .mobile-table .cds--data-table {
  border: 2px solid rgba(34, 96, 100, 0.2);
  background-color: white !important;
  box-shadow: 0 0 0 1px rgba(0, 10, 30, .2) inset;
} */

.mobile-table .cds--data-table tbody td,
.mobile-table .cds--data-table tbody td:hover {
  background-color: white !important;
}

.simple-table .cds--data-table th {
  text-transform: uppercase !important;
  font-size: 13px;
}

.simple-table .cds--data-table th,
.simple-table .cds--data-table thead,
.simple-table .cds--pagination,
.simple-table .cds--pagination .cds--pagination__text {
  color: black !important;
  background-color: white !important;
}

.simple-table .cds--data-table tr:hover,
.simple-table .cds--data-table tbody th:hover,
.simple-table .cds--data-table tbody td,
.simple-table .cds--data-table tbody td:hover {
  background-color: white !important;
}

.x-table-scroll {
  overflow-x: scroll;
}

.x-table-scroll-nt {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.x-table-scroll-nt::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body .cds--data-table th.actionable,
body .cds--data-table td.actionable {
  width: 35px;
  z-index: 99;
  padding: 0.6rem 0.6rem 0 0.6rem !important;
  /* background-color: #fdfdfd !important; */
}

.actionable.sticky {
  position: sticky !important;
  left: 0;
}


.mobile-table .cds--table-toolbar {
  height: auto !important;
  flex-direction: column !important;
}

.mobile-table .cds--table-toolbar .cds--toolbar-search-container-active {
  width: 100% !important;
}

.mobile-table .cds--data-table-container .cds--toolbar-content {
  height: auto !important;
  gap: 5px;
  justify-content: center !important;
  flex-wrap: wrap !important;
  margin: 1rem 0 !important;
}

.mobile-table .cds--data-table-container .cds--toolbar-content>.cds--btn {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.mobile-table .cds--data-table-container {
  min-width: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
}

.mobile-table .cds--data-table thead {
  display: none !important;
}

.mobile-table .cds--data-table tbody tr .cds--table-column-checkbox {
  display: none !important;
}

.mobile-table .cds--data-table-container .cds--toolbar-content .cds--btn {
  width: 100% !important;
}

.cds--data-table> :not(thead) th {
  padding: 0rem 1rem !important;
}

.cds--data-table-v2 thead th .text-center {
  display: block;
}

.cds--toolbar-search-container-active {
  width: 100% !important;
}

.cds--batch-actions--active {
  z-index: 1111;
}

.text-center .cds--table-header-label {
  text-align: center !important;
}

tbody.candidate-background-color tr:nth-child(2n),
tbody.candidate-background-color tr:nth-child(2n) td {
  background-color: #726c6c0d;
  vertical-align: top !important;
}

tbody.candidate-background-color tr:nth-child(2n - 1),
tbody.candidate-background-color tr:nth-child(2n - 1) td {
  background-color: white;
  vertical-align: top !important;
}

.css-sm-tile-b {
  border: 1px solid #bbbfc3 !important;
}

.csc-table-search-expandable {
  border: 1.5px solid gray !important;
}

.cds--row {
  transition: transform 0.3s ease-in-out;
}

/* Highlight moved row */
.highlighted {
  background-color: yellow !important;
  transition: background-color 1s ease-in-out;
}